/**
 * Burgerprofiel Brainlane bonnen integratie
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum DeliveryMethod {
    SEND_TO_ADDRESS = 'send_to_address',
    PICK_UP = 'pick_up',
    EMAIL = 'email'
}

