/**
 * Burgerprofiel Brainlane bonnen integratie
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum OrderCustomerType {
    BUSINESS = 'business',
    INDIVIDUAL = 'individual'
}

